import { Flex } from 'cdk'
import {
  BORDER_RADIUS_SM,
  BORDER_WIDTH_0_5,
  BORDER_WIDTH_2,
  COLOR_GREY_110,
  COLOR_VIOLET_50,
  ELEVATION_SM,
  ELEVATION_XXS,
  SPACING_3XS,
  SPACING_MD,
  SPACING_SM,
  SPACING_XS,
  TIME_150,
} from 'design-tokens'
import { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Text, Tile } from 'ui'
import { TherapyPathKey } from '~/domains/agenda/components/StartTherapyPath/components/StartTherapyPathCard'
import { StartTherapyPathIcon } from '~/domains/agenda/components/StartTherapyPath/components/StartTherapyPathIcon'
import { StartTherapyPathName } from '~/domains/agenda/components/StartTherapyPath/components/StartTherapyPathName'
import { validFirstTherapySessionFree } from '~/domains/therapies/constants'
import { TranslationId } from '~/i18n/types'
import { ChipFirstTherapySessionFree } from '../ChipFirstTherapySessionFree'
import { Translation } from '../Translation'

type TherapyPathProps = {
  $active?: boolean
  therapyPathType: TherapyPathKey
}

const therapyPathTranslations: Record<
  TherapyPathKey,
  Extract<TranslationId, `therapies.startTherapyPath.card.description.${string}`>
> = {
  MYSELF_PSYCHOTHERAPY: 'therapies.startTherapyPath.card.description.individual',
  COUPLES_PSYCHOTHERAPY: 'therapies.startTherapyPath.card.description.couples',
  PATH_COACHING: 'therapies.startTherapyPath.card.description.coaching',
  PATH_NUTRITION_WEIGHT_LOSS: 'therapies.startTherapyPath.card.description.nutrition.weightLoss',
  PATH_NUTRITION_DCA: 'therapies.startTherapyPath.card.description.nutrition.dca',
  PATH_SEXOLOGY: 'therapies.startTherapyPath.card.description.sexology',
  MYSELF_PSYCHIATRY: 'therapies.startTherapyPath.card.description.psychiatry',
}

const TherapyPathContainer = styled(Tile)<Pick<TherapyPathProps, '$active'>>`
  padding: ${SPACING_SM};
  border: 0;
  border-radius: ${BORDER_RADIUS_SM};
  transition:
    outline ${TIME_150} ease,
    box-shadow ${TIME_150} ease;

  ${({ $active }) => css`
    box-shadow: ${$active ? ELEVATION_SM : ELEVATION_XXS};
    outline: ${BORDER_WIDTH_2} solid ${$active ? COLOR_VIOLET_50 : 'none'};

    &:hover {
      box-shadow: ${ELEVATION_SM};
      outline: ${$active
        ? `${BORDER_WIDTH_2} solid ${COLOR_VIOLET_50}`
        : `${BORDER_WIDTH_0_5} solid ${COLOR_GREY_110}`};
    }
  `};
`

export const TherapyPathCard = ({ $active = false, therapyPathType }: TherapyPathProps) => {
  const showChip = useMemo(() => validFirstTherapySessionFree.includes(therapyPathType), [therapyPathType])

  return (
    <TherapyPathContainer $active={$active}>
      <Flex $direction="column" $gap={SPACING_XS}>
        <Flex $align="center" $direction="row" $gap={SPACING_MD} $justify="space-between">
          <StartTherapyPathIcon size={32} therapyPathType={therapyPathType} />
          {showChip && (
            <Flex>
              <ChipFirstTherapySessionFree />
            </Flex>
          )}
        </Flex>
        <Flex $gap={SPACING_3XS}>
          <StartTherapyPathName kind="h3" therapyPathType={therapyPathType} />
          <Text kind="paragraph">
            <Translation id={therapyPathTranslations[therapyPathType]} />
          </Text>
        </Flex>
      </Flex>
    </TherapyPathContainer>
  )
}
