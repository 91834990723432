import { Flex } from 'cdk'
import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'
import { FieldMarketingEmails } from '~/domains/react-hook-form/fields/FieldMarketingEmails'
import { FieldProfilation, FieldProfilationVariant } from '~/domains/react-hook-form/fields/FieldProfilation'
import {
  FieldTermsAndConditions,
  FieldTermsAndConditionsVariant,
} from '~/domains/react-hook-form/fields/FieldTermsAndConditions'

export const SignupTermsAndConditions = () => {
  const { isVariant } = useFeatureFlagsByFingerprint()
  const isSignupMultivariantVariant = isVariant('ff_signup_multivariant')
  const isNewCheckbox = isVariant('ff_new_checkbox')

  return (
    <Flex $gap={16}>
      {isSignupMultivariantVariant && <FieldMarketingEmails />}

      {isNewCheckbox ? <FieldTermsAndConditionsVariant /> : <FieldTermsAndConditions />}

      {isNewCheckbox ? <FieldProfilationVariant /> : <FieldProfilation />}

      {!isSignupMultivariantVariant && <FieldMarketingEmails />}
    </Flex>
  )
}
