import { Flex } from 'cdk'
import { COLOR_VIOLET_50, SPACING_XS } from 'design-tokens'
import styled from 'styled-components'
import { Text } from 'ui'
import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { ReactHookFormCheckboxField } from '../components'
import { ReactHookFormCheckboxFieldVariant } from '../components/ReactHookFormCheckboxFieldVariant'

// NOTE This is a temporary solution. All future form fields will be handled within the CheckboxButton component
const TextPointer = styled(Text)`
  cursor: pointer;

  &:hover {
    color: ${COLOR_VIOLET_50};
  }
`

export const FieldMarketingEmails = () => {
  const { labels } = useFormTranslations()

  const { isVariant1 } = useFeatureFlagsByFingerprint()
  const isNewCheckbox = isVariant1('ff_new_checkbox')

  return isNewCheckbox ? (
    <Flex $align="center" $direction="row" $gap={SPACING_XS}>
      <ReactHookFormCheckboxFieldVariant name="marketingEmails" />
      <Flex $shrink={1}>
        <TextPointer as="label" htmlFor="marketingEmails" kind="caption">
          {labels.marketingEmails}
        </TextPointer>
      </Flex>
    </Flex>
  ) : (
    <ReactHookFormCheckboxField name="marketingEmails">
      <Flex $pt={2}>
        <Text as="label" fontWeight="400" htmlFor="marketingEmails" kind="caption">
          {labels.marketingEmails}
        </Text>
      </Flex>
    </ReactHookFormCheckboxField>
  )
}
