import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { AssertFeatureFlagVariant1Redirect } from '~/domains/featureFlags/components/AssertFeatureFlagVariant1Redirect'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'

const LazyFoodJournalPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.FoodJournalPageView,
})

export const FoodJournalPage: Page = {
  exact: true,
  id: 'foodJournal',
  paths: ['/food-journal'],
  options: {
    isVisibleAppBar: false,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'foodJournal',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertFeatureFlagVariant1Redirect name="ff_nutrition">
        <AssertPatient>{children}</AssertPatient>
      </AssertFeatureFlagVariant1Redirect>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyFoodJournalPageView />,
}
