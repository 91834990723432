import { ComponentProps } from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { RadioButton } from 'ui'
import { CheckboxField } from 'ui-deprecated'
import { useLoadingState } from '~/hooks/useLoadingState'

type CheckboxProps = ComponentProps<typeof CheckboxField>

type Props = CheckboxProps & {
  rules?: RegisterOptions
}

export const ReactHookFormRadioFieldVariant = ({ disabled, name, rules, value: inputValue, ...props }: Props) => {
  const { control } = useFormContext()
  const { loading } = useLoadingState()

  return (
    <Controller
      control={control}
      defaultValue={false}
      name={name}
      render={({ field: { onChange, value, ref }, formState: { isSubmitting, isValid } }) => (
        <RadioButton
          {...props}
          ref={ref}
          checked={value === inputValue}
          disabled={disabled || (isValid && (isSubmitting || loading))}
          name={name}
          onChange={onChange}
          value={inputValue}
        />
      )}
      rules={rules}
    />
  )
}
