import { secondsToMilliseconds, secondsToMinutes, toDate } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { noop } from 'functions'
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { useModals } from '~/domains/modals'
import { useCurrentUserNullable } from '~/hooks/useCurrentUser'
import { useToasts } from '~/hooks/useToasts'
import { getEnv } from '~/utils/getEnv'
import { useEmailVerificationRequest } from './useEmailVerificationRequest'
import { useEmailVerificationVerify } from './useEmailVerificationVerify'

type VerifyParam = {
  codeToVerify: string
  onFailure?: () => void
  onSuccess?: () => void
  userId: string
}

type Context = {
  countdown: number
  emailVerificationRequest: (emailToVerify: string) => Promise<void>
  emailVerificationVerify: ({ codeToVerify, userId }: VerifyParam) => Promise<void>
  loading: boolean
  sending: boolean
}

type Props = {
  children: ReactNode
}

const EmailerificationContext = createContext<Context | null>(null)

const waitToResetAttemptsInSeconds = 180

const waitForNextAttemptInSeconds = 30

let countdownTimeout: undefined | number

export const EmailVerificationContextProvider = ({ children }: Props) => {
  const [attempts, setAttempts] = useState(0)
  const [countdown, setCountdown] = useState(0)
  const [emailVerificationRequest] = useEmailVerificationRequest()
  const [emailVerificationVerify] = useEmailVerificationVerify()
  const [loading, setLoading] = useState(false)
  const [sending, setSending] = useState(false)
  const { addToast } = useToasts()
  const { close } = useModals()
  const { email, updateQuery } = useCurrentUserNullable()
  const { isAuthenticated } = useAuthState()

  useEffect(() => {
    if (countdown === 0) {
      window.clearTimeout(countdownTimeout)

      return
    }

    countdownTimeout = window.setTimeout(
      () => {
        setCountdown(countdown - 1)
      },
      pipe(1, secondsToMilliseconds),
    )
  }, [countdown])

  const handleEmailVerificationRequest = useCallback(
    async (emailToVerify: string) => {
      if (sending && email && emailToVerify === email) {
        addToast({
          type: 'warning',
          translationId: 'emailVerification.request.sending',
          translationValues: { count: countdown },
        })

        return
      }

      setLoading(true)

      if (attempts >= pipe('SERENIS_EMAIL_VERIFICATION_MAX_ATTEMPTS', getEnv, Number)) {
        setLoading(false)

        setCountdown(waitToResetAttemptsInSeconds)

        addToast({
          type: 'alert',
          translationId: 'emailVerification.request.waitToRetry',
          translationValues: { count: pipe(waitToResetAttemptsInSeconds, secondsToMinutes) },
        })

        setTimeout(
          () => {
            setAttempts(0)
          },
          pipe(waitToResetAttemptsInSeconds, secondsToMilliseconds),
        )

        return
      }

      try {
        setSending(true)

        const { data } = await emailVerificationRequest({
          variables: {
            input: {
              emailToVerify,
            },
          },
        })

        if (!data?.emailVerificationRequest.ok) {
          addToast({
            type: 'alert',
            translationId: 'generic.errorRetryLater',
          })

          setLoading(false)

          setSending(false)

          return
        }

        setAttempts((a) => a + 1)

        updateQuery((state) => {
          if (!state.me) {
            return state
          }

          return {
            ...state,
            me: {
              ...state.me,
              email: emailToVerify,
              emailVerifiedAt: null,
            },
          }
        })

        setLoading(false)

        addToast({
          type: 'success',
          translationId: 'emailVerification.request.success',
          translationValues: { email: emailToVerify },
        })

        setCountdown(waitForNextAttemptInSeconds)

        window.setTimeout(
          () => {
            setSending(false)
          },
          pipe(waitForNextAttemptInSeconds, secondsToMilliseconds),
        )
      } catch (error) {
        addToast({
          type: 'alert',
          translationId: 'generic.errorRetryLater',
        })

        setLoading(false)

        setSending(false)
      }
    },
    [addToast, attempts, countdown, email, emailVerificationRequest, sending, updateQuery],
  )

  const handleEmailVerificationVerify = useCallback(
    async ({ codeToVerify, onFailure = noop, onSuccess = noop, userId }: VerifyParam) => {
      if (loading) {
        onFailure()

        return
      }

      setLoading(true)

      try {
        const { data } = await emailVerificationVerify({
          variables: {
            input: {
              codeToVerify,
              userId,
            },
          },
        })

        if (!data?.emailVerificationVerify.ok) {
          setLoading(false)

          addToast({ type: 'alert', translationId: 'emailVerification.verify.failure' })

          onFailure()

          return
        }

        if (isAuthenticated) {
          updateQuery((state) => {
            if (!state.me) {
              return state
            }

            return {
              ...state,
              me: {
                ...state.me,
                emailVerifiedAt: pipe(nowInMilliseconds(), toDate),
              },
            }
          })
        }

        addToast({ type: 'success', translationId: 'emailVerification.verify.success' })

        setLoading(false)

        close('emailVerification')

        onSuccess()
      } catch (error) {
        setLoading(false)

        addToast({ type: 'alert', translationId: 'emailVerification.verify.failure' })

        onFailure()
      }
    },
    [addToast, close, emailVerificationVerify, isAuthenticated, loading, updateQuery],
  )

  const context: Context = {
    countdown,
    emailVerificationRequest: handleEmailVerificationRequest,
    emailVerificationVerify: handleEmailVerificationVerify,
    loading,
    sending,
  }

  return <EmailerificationContext.Provider value={context}>{children}</EmailerificationContext.Provider>
}

export const useEmailVerificationContext = () => {
  const context = useContext(EmailerificationContext)

  if (!context) {
    throw new Error('The `useEmailVerificationContext` should be wrapped with `EmailVerificationContextProvider`.')
  }

  return context
}
