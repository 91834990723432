import { memo } from 'react'
import { Text } from 'ui'
import { Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { MenuNativeAppVersion } from '~/domains/appNative/components/MenuNativeAppVersion'
import { useIsNativeApp } from '~/domains/appNative/hooks/useIsNativeApp'
import { MenuLanguageSwitcher } from '~/i18n/components/MenuLanguageSwitcher'
import { MenuHeader } from './components/MenuHeader'
import { MenuItemAccount } from './components/MenuItemAccount'
import { MenuItemCommunications } from './components/MenuItemCommunications'
import { MenuItemFAQ } from './components/MenuItemFAQ'
import { MenuItemHistoricalTherapySessions } from './components/MenuItemHistoricalTherapySessions'
import { MenuItemLogout } from './components/MenuItemLogout'
import { MenuItemNotifications } from './components/MenuItemNotifications'
import { MenuItemProfile } from './components/MenuItemProfile'
import { MenuItemReview } from './components/MenuItemReview'
import { MenuItemWriteUs } from './components/MenuItemWriteUs'

export const TherapistTabMenu = memo(() => {
  const isNativeApp = useIsNativeApp()

  return (
    <>
      <MenuHeader />

      <OverflowAuto p={16}>
        <Flex pb={24}>
          <Text colorName="purple-08" fontWeight="600" kind="paragraph">
            <Translation id="menu.menu" />
          </Text>
        </Flex>

        <Flex pb={24}>
          <MenuItemAccount />
        </Flex>

        <Flex pb={24}>
          <MenuItemHistoricalTherapySessions />
        </Flex>

        <Flex pb={24}>
          <MenuItemCommunications />
        </Flex>

        {isNativeApp && (
          <Flex pb={24}>
            <MenuItemNotifications />
          </Flex>
        )}

        <Flex pb={24}>
          <MenuItemProfile />
        </Flex>

        <Flex py={24}>
          <Text colorName="purple-08" fontWeight="600" kind="paragraph">
            <Translation id="menu.youOkay" />
          </Text>
        </Flex>

        <Flex pb={24}>
          <MenuItemReview />
        </Flex>

        <Flex py={24}>
          <Text colorName="purple-08" fontWeight="600" kind="paragraph">
            <Translation id="menu.help" />
          </Text>
        </Flex>

        <Flex pb={24}>
          <MenuItemFAQ />
        </Flex>

        <Flex pb={24}>
          <MenuItemWriteUs />
        </Flex>

        <Flex justify="flex-end" pb={24} pt={40}>
          <MenuItemLogout />
        </Flex>

        <Flex pb={24}>
          <MenuLanguageSwitcher />
        </Flex>

        <MenuNativeAppVersion />
      </OverflowAuto>
    </>
  )
})
