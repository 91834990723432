import { Flex } from 'cdk'
import { COLOR_VIOLET_50, SPACING_XS } from 'design-tokens'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { Text } from 'ui'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { ReactHookFormCheckboxField } from '../components'
import { ReactHookFormCheckboxFieldVariant } from '../components/ReactHookFormCheckboxFieldVariant'

export const FieldProfilation = () => {
  const { labels } = useFormTranslations()

  const { formState, watch } = useFormContext()
  const profilation = watch('profilation')

  return (
    <ReactHookFormCheckboxField name="profilation" rules={{ required: true }}>
      <Flex $pt={2}>
        <Text
          as="label"
          colorName={formState.isSubmitted && !profilation ? 'coral-10' : 'black'}
          fontWeight="400"
          htmlFor="profilation"
          kind="caption"
        >
          {labels.profilation}
        </Text>
      </Flex>
    </ReactHookFormCheckboxField>
  )
}

// NOTE This is a temporary solution. All future form fields will be handled within the CheckboxButton component
const TextPointer = styled(Text)`
  cursor: pointer;

  &:hover {
    color: ${COLOR_VIOLET_50};
  }
`

export const FieldProfilationVariant = () => {
  const { labels } = useFormTranslations()

  const { formState, watch } = useFormContext()
  const profilation = watch('profilation')

  return (
    <Flex $align="center" $direction="row" $gap={SPACING_XS}>
      <ReactHookFormCheckboxFieldVariant name="profilation" rules={{ required: true }} />
      <Flex $shrink={1}>
        <TextPointer
          as="label"
          colorName={formState.isSubmitted && !profilation ? 'coral-10' : 'black'}
          fontWeight="400"
          htmlFor="profilation"
          kind="caption"
        >
          {labels.profilation}
        </TextPointer>
      </Flex>
    </Flex>
  )
}
