import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { AssertFeatureFlagVariant1Redirect } from '~/domains/featureFlags/components/AssertFeatureFlagVariant1Redirect'
import { PatientTherapiesProvider } from '~/domains/patient/hooks/usePatientTherapies'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'
import { ClinicalTestPageGuard } from './Guard'

const LazyClinicalTest = loadable(() => import('./View'), {
  resolveComponent: (components) => components.View,
})

export const ClinicalTestPage: Page = {
  exact: false,
  id: 'clinicalTest',
  paths: ['/clinical-test/:clinicalTestAnswerId'],
  options: {
    isVisibleAppBar: false,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'clinicalTest',
    title: 'seo.clinicalTest.title',
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertFeatureFlagVariant1Redirect name="ff_clinical_tests">
        <AssertPatient>
          <ClinicalTestPageGuard>{children}</ClinicalTestPageGuard>
        </AssertPatient>
      </AssertFeatureFlagVariant1Redirect>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: ({ children }) => <PatientTherapiesProvider>{children}</PatientTherapiesProvider>,
  PageView: () => <LazyClinicalTest />,
}
