import { gql, useMutation } from '@apollo/client'
import { toDate } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { useCallback, useState } from 'react'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { useCurrentUserNullable } from '~/hooks/useCurrentUser'
import { useToasts } from '~/hooks/useToasts'

const EMAIL_VERIFICATION_REQUEST = gql`
  mutation emailVerificationRequest($input: EmailVerificationRequestInput!) {
    emailVerificationRequest(input: $input) {
      ok
    }
  }
`

const EMAIL_VERIFICATION_VERIFY = gql`
  mutation emailVerificationVerify($input: EmailVerificationVerifyInput!) {
    emailVerificationVerify(input: $input) {
      ok
    }
  }
`

export const useEmailVerification = () => {
  const { addToast } = useToasts()
  const { updateQuery } = useCurrentUserNullable()
  const { isAuthenticated } = useAuthState()
  const [error, setError] = useState<boolean>(false)

  const [emailVerificationRequest, { loading: sendingEmail }] = useMutation(EMAIL_VERIFICATION_REQUEST)
  const [emailVerificationVerify, { loading: verifyingCode }] = useMutation(EMAIL_VERIFICATION_VERIFY)

  const loading = sendingEmail || verifyingCode

  const requestVerification = useCallback(
    async (email: string): Promise<boolean> => {
      setError(false)
      try {
        const { data } = await emailVerificationRequest({ variables: { input: { emailToVerify: email } } })
        if (!data.emailVerificationRequest.ok) {
          setError(true)
          addToast({ type: 'alert', translationId: 'generic.errorRetryLater' })
          return false
        }

        updateQuery((state) => {
          if (!state.me) {
            return state
          }

          return {
            ...state,
            me: { ...state.me, email, emailVerifiedAt: null },
          }
        })

        addToast({
          type: 'success',
          translationId: 'emailVerification.request.success',
          translationValues: { email },
        })

        return true
      } catch (err) {
        setError(true)
        addToast({ type: 'alert', translationId: 'generic.errorRetryLater' })
        return false
      }
    },
    [addToast, emailVerificationRequest, updateQuery],
  )

  const verifyCode = useCallback(
    async (userId: string, code: string): Promise<boolean> => {
      setError(false)
      try {
        const { data } = await emailVerificationVerify({ variables: { input: { codeToVerify: code, userId } } })
        if (!data.emailVerificationVerify.ok) {
          setError(true)
          return false
        }

        if (isAuthenticated) {
          updateQuery((state) => {
            if (!state.me) {
              return state
            }

            return {
              ...state,
              me: { ...state.me, emailVerifiedAt: pipe(nowInMilliseconds(), toDate) },
            }
          })
        }
        addToast({ type: 'success', translationId: 'emailVerification.verify.success' })
        return true
      } catch (err) {
        setError(true)
        return false
      }
    },
    [addToast, emailVerificationVerify, isAuthenticated, updateQuery],
  )

  return {
    requestVerification,
    verifyCode,
    error,
    loading,
  }
}
