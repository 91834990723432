import { useEffect } from 'react'
import { useTrackEvent } from './useTrackEvent'

export type EventViewId =
  | 'auth.forgot-password'
  | 'auth.login'
  | 'auth.reset-password'
  | 'auth.signup.credentials'
  | 'auth.signup.name'
  | 'auth.signup.policies'
  | 'auth.signup'
  | 'auth.token'
  | 'availabilities.calendar'
  | 'availabilities.weekly'
  | 'booking.calendar'
  | 'booking.payment'
  | 'bundles'
  | 'bundles.step.intro'
  | 'bundles.step.therapy'
  | 'bundles.step.paymentMethod.add'
  | 'bundles.step.paymentMethod.confirm'
  | 'bundles.step.billing'
  | 'bundles.step.recap'
  | 'changeTherapist.booking'
  | 'changeTherapist.formFlow'
  | 'changeTherapist.preferences.formFlow'
  | 'changeTherapist.modal.info'
  | 'changeTherapist.modal.auto'
  | 'changeTherapist.modal.manual'
  | 'changeTherapist.modal.open'
  | 'chat'
  | 'clinicalTest'
  | 'clinicalTest.step.intro'
  | `clinicalTest.step.question.${string}`
  | 'clinicalTest.step.end'
  | 'communications'
  | 'diagnosis.edit'
  | 'foodJournal'
  | 'historicalTherapySessions'
  | 'home'
  | 'invoices'
  | 'journaling.benefits'
  | 'journaling.log'
  | 'journaling.log.edit'
  | 'journaling.log.share'
  | 'journaling.settings'
  | 'journaling.settings.activities'
  | 'journaling.settings.goalInDays'
  | 'journaling.settings.notification'
  | 'journaling.logs'
  | 'journaling.tipsOfLove.activityCheck'
  | 'journaling.tipsOfLove.moods'
  | 'journaling.tipsOfLove.success'
  | 'journaling.tipsOfLove.thankfulReasons'
  | 'journaling.tipsOfLove.todayThoughts'
  | 'journaling.tipsOfLove'
  | 'journaling'
  | 'onboarding'
  | 'onboarding.couples'
  | 'onboarding.guardian'
  | 'plan'
  | 'notifications.journaling'
  | 'notifications'
  | 'payment.billing'
  | 'payment'
  | 'payments.confirm'
  | 'productReview.detail'
  | 'profile'
  | 'profile.education'
  | 'reservation.book-by-matching-therapists'
  | 'reservation.booking'
  | 'reservation.check-verification-code'
  | 'reservation.no-matched-therapists'
  | 'reservation.payment-method'
  | 'reservation.send-verification-code'
  | 'reservation.skipBooking'
  | 'reservation.skipBookingCoaching'
  | 'reservation.skipBookingPsychiatry'
  | 'reservation.submit'
  | 'reservation'
  | 'saas.onboardingInternal'
  | 'settings'
  | 'settings.account'
  | 'settings.activities'
  | 'settings.billing'
  | 'settings.plans'
  | 'settings.languages'
  | 'settings.payments.add'
  | 'settings.payments.confirm'
  | 'settings.payments'
  | 'settings'
  | 'settings.pathologies'
  | 'settings.patientTypes'
  | 'settings.professionalSpecializations'
  | 'settings.styles'
  | 'settings.techniques'
  | 'settings.workplaces'
  | 'share.therapy-session.reward'
  | 'share.sentence'
  | 'start.form.analyzing'
  | 'start.form.end'
  | 'start.form.welcome'
  | 'start.form'
  | 'start.thanks'
  | 'stickers'
  | 'therapies'
  | 'therapist.patientsManagement'
  | 'therapist.profile'
  | 'therapy-already-ongoing'
  | 'therapy-sessions.booking'
  | 'therapy-sessions.confirm'
  | 'therapy-sessions.edit'
  | 'therapy-sessions.ended'
  | 'therapy-sessions.live'
  | 'therapy-sessions.live.error.connected-from-another-device'
  | 'therapy-sessions.live.error.generic'
  | 'therapy-sessions.live.error.reconnecting'
  | 'therapy-sessions.live.external'
  | 'therapy-sessions.live.succeeded'
  | 'therapy-sessions.live.waiting-room.early'
  | 'therapy-sessions.live.waiting-room.ended'
  | 'therapy-sessions.live.waiting-room.in-the-past'
  | 'therapy-sessions.live.waiting-room.joinable'
  | 'therapy-sessions.live.waiting-room.startable'
  | 'therapy-sessions.live.waiting-room.therapist-is-late'
  | 'therapy-sessions.live.waiting-room.waiting-for-therapist'
  | 'therapy-sessions.live.waiting-room'
  | 'therapy-sessions.schedule'
  | 'verify.email'
  | 'videocall.troubleshooting'

export const useTrackEventView = (id: EventViewId) => {
  const trackEvent = useTrackEvent('VIEW')

  useEffect(() => {
    trackEvent({ name: `views.${id}` })
  }, [id, trackEvent])
}
