import { Flex, MaxWidth640px } from 'cdk'
import { COLOR_PRIMARY, COLOR_VIOLET_20, COLOR_VIOLET_30, SPACING_MD, SPACING_XL } from 'design-tokens'
import { Link } from 'react-router-dom-v5-compat'
import { Button, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { getRoute } from '~/utils/getRoute'
import { useClinicalTestAnswer } from '../hooks/useClinicalTestAnswer'

const CongratsSvg = () => (
  <svg fill="none" viewBox="0 0 120 120" width={120} xmlns="http://www.w3.org/2000/svg">
    <path
      d="m56.5 47.43 5.26 20.39.06.22c.5 1.83 7.03 27.01-10.7 30.95-15.05 4.55-26.56-8.7-35.76-19.28l-.07-.08a4.59 4.59 0 0 1 1.63-7.2 4.46 4.46 0 0 1 3-.19l9.15 2.66-5.68-20.99a7.17 7.17 0 0 1 5.68-8.93l-.64-2.33a4.72 4.72 0 0 1 3.9-5.92l.8-.1a3.88 3.88 0 0 1 4.34 3.12l.44 2.33a4.55 4.55 0 0 1 8.94-1.75l.62 3.06c3.61-1.87 8.02.1 9.03 4.04Z"
      fill="url(#a)"
    />
    <path d="M29.3 75.24c4.61-2.5 16.53-3.42 16.53 11.86" stroke="#000" strokeWidth=".91" />
    <path
      d="m93.77 59.56 11.2-2.08 3.64 28.4-23.18 5.69c-15.3 4.62-35.5-5.84-46.88-15.69-2.45-2.12-2.26-5.85.17-8a5.78 5.78 0 0 1 6.98-.5l8.14 5.32L47 47.1a7.2 7.2 0 0 1 5.7-8.94l-.63-2.33a4.72 4.72 0 0 1 3.9-5.91l.8-.11a3.88 3.88 0 0 1 4.34 3.12l.44 2.33a4.55 4.55 0 0 1 8.94-1.75l.62 3.06a6.33 6.33 0 0 1 9.03 4.04L83.3 52.9a9.1 9.1 0 0 0 10.46 6.66Z"
      fill="#D9D9D9"
    />
    <path
      d="m93.77 59.56 11.2-2.08 3.64 28.4-23.18 5.69c-15.3 4.62-35.5-5.84-46.88-15.69-2.45-2.12-2.26-5.85.17-8a5.78 5.78 0 0 1 6.98-.5l8.14 5.32L47 47.1a7.2 7.2 0 0 1 5.7-8.94l-.63-2.33a4.72 4.72 0 0 1 3.9-5.91l.8-.11a3.88 3.88 0 0 1 4.34 3.12l.44 2.33a4.55 4.55 0 0 1 8.94-1.75l.62 3.06a6.33 6.33 0 0 1 9.03 4.04L83.3 52.9a9.1 9.1 0 0 0 10.46 6.66Z"
      fill={COLOR_VIOLET_20}
    />
    <path
      clipRule="evenodd"
      d="M48.97 11.79a11.2 11.2 0 0 1-13.73 7.92 11.2 11.2 0 1 0 21.65 5.8 11.2 11.2 0 0 0-13.72 7.91 11.2 11.2 0 0 0-7.93-13.71m21.65 5.8a11.2 11.2 0 0 1-7.92-13.72l7.92 13.72Z"
      fill={COLOR_VIOLET_30}
      fillRule="evenodd"
    />
    <path
      d="m71.11 36.82 4.72 23.33m-13.6-24.72 4.71 23.33m-13.6-21.1 4.72 23.33M29.44 45.43l4.73 23.33m3.6-26.1 4.73 23.33"
      stroke="#000"
      strokeWidth="1.11"
    />
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" id="a" x1="37.26" x2="37.26" y1="35.58" y2="99.91">
        <stop stopColor="#F6BFFF" />
        <stop offset="1" stopColor={COLOR_PRIMARY} />
      </linearGradient>
    </defs>
  </svg>
)

type ClinicalTestEndStepProps = {
  clinicalTestAnswerId: string
}

export const ClinicalTestEndStep = ({ clinicalTestAnswerId }: ClinicalTestEndStepProps) => {
  const { clinicalTestAnswer } = useClinicalTestAnswer(clinicalTestAnswerId)
  const { therapies } = usePatientTherapies()
  const trackClick = useTrackEventClick()

  if (!clinicalTestAnswer) {
    return null
  }

  const therapist = therapies.find((therapy) => therapy.id === clinicalTestAnswer.therapyId)?.therapist

  return (
    <Flex $grow={1} $justify="space-between">
      <MaxWidth640px $align="flex-start" $gap={SPACING_XL} $px={SPACING_MD} $py={SPACING_XL} $zIndex={1}>
        <CongratsSvg />
        <Flex $gap={SPACING_MD}>
          <Text kind="h1">
            <Translation id="clinicalTests.tests.end.title" />
          </Text>
          <Text kind="paragraph">
            <Translation
              id="clinicalTests.tests.end.description"
              values={{ therapistFullName: therapist?.fullName ?? '' }}
            />
          </Text>
        </Flex>
        <Link to={getRoute(`/chat/${therapist?.id}`)}>
          <Button kind="primary" onClick={() => trackClick('clinical-test.step.end.return-to-chat')}>
            <Translation id="clinicalTests.tests.end.action" />
          </Button>
        </Link>
      </MaxWidth640px>
    </Flex>
  )
}
