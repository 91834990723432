import { Spacing } from '@serenis-health/tokens'
import { isAfter } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { useCallback, useMemo } from 'react'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import { Button, ButtonProps, Flex } from 'ui-deprecated'
import { RouterLinkButton } from '~/components/RouterLinkButton'
import { StopTherapySessionButton } from '~/components/TherapySessionCardTherapist/components/StopTherapySessionButton'
import { Translation } from '~/components/Translation'
import { TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { useTherapySessionsTherapistAgenda } from '~/domains/therapy-session/hooks/useTherapySessionsTherapistAgenda'
import { getPatientDetailRoute } from '~/domains/therapy-session/videocall/VideocallSidePanel/contents/PatientDetail/getPatientDetailRoute'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { usePatientId } from '~/hooks/usePatientId'
import { TherapySessionStatus, VideocallProvider } from '~/types/graphql'
import { getGoogleMeetUrlWithEmail } from '~/utils/getGoogleMeetUrlWithEmail'
import { Route, getRoute } from '~/utils/getRoute'

type JoinVideocallButtonProps = {
  url: string
  patientId: string
  provider: VideocallProvider | null
  status: TherapySessionStatus
}

const JoinVideocallButton = ({ url, patientId, provider, status }: JoinVideocallButtonProps) => {
  const history = useHistory()
  const { email } = useCurrentUser()

  const onClick = useCallback(() => {
    history.push(getRoute(`/chat/${patientId}/detail`))

    window.open(getGoogleMeetUrlWithEmail(url, email), '_blank')
  }, [email, history, patientId, url])

  const ongoing = status === 'ONGOING'

  if (provider === 'HOUSE') {
    return (
      <Flex>
        <RouterLinkButton
          kind="primary"
          liveAnimation={ongoing}
          size="small"
          to={url as Route}
          translationId={
            ongoing ? 'therapySession.generic.ongoing.goToTherapySession' : 'therapySession.generic.ongoing.cta'
          }
        />
      </Flex>
    )
  }

  return (
    <Button kind="primary" onClick={onClick} size="small">
      <Translation
        id={ongoing ? 'therapySession.generic.ongoing.goToTherapySession' : 'therapySession.generic.ongoing.cta'}
      />
    </Button>
  )
}

const BookNextButton = ({ kind = 'primary', therapyId }: Pick<ButtonProps, 'kind'> & { therapyId: string }) => {
  const { pathname } = useLocation()

  const targetPatientId = usePatientId()

  const redirectLink: Route = !!matchPath(pathname, { path: [getRoute('/chat')] })
    ? `/chat/${targetPatientId}/detail/therapy-session/${therapyId}/schedule`
    : !!matchPath(pathname, { path: ['/detail'] })
      ? `/detail/therapy-session/${therapyId}/schedule`
      : `/therapy-session/${therapyId}/schedule`

  return (
    <RouterLinkButton
      kind={kind}
      size="small"
      to={redirectLink}
      translationId="therapySession.therapist.scheduleNextSession"
    />
  )
}

type Props = Pick<
  TherapistAgendaTherapySession,
  'churn' | 'id' | 'patient' | 'provider' | 'recurrency' | 'startAt' | 'status' | 'therapist' | 'url'
> & { isInTherapySessionLive: boolean; therapyId: string }

export const TherapySessionActions = ({
  churn,
  id,
  isInTherapySessionLive,
  patient,
  provider,
  recurrency,
  startAt,
  status,
  therapist,
  therapyId,
  url,
}: Props) => {
  const { items } = useTherapySessionsTherapistAgenda()

  const { pathname } = useLocation()
  const matchChatPath = !!matchPath(pathname, { path: [getRoute('/chat')] })
  const matchPatientDetailPath = !!matchPath(pathname, { path: [getPatientDetailRoute('/detail')] })
  const matchPatientPath = !!matchPath(pathname, { path: [getRoute('/patients')] })

  const futureTherapySession = useMemo(
    () =>
      items.find(
        (future) =>
          future.parentTherapySessionId === id ||
          (future.patient.id === patient.id &&
            future.therapist &&
            therapist &&
            future.therapist.id === therapist.id &&
            pipe(future.startAt, isAfter(startAt))),
      ),
    [id, items, patient.id, startAt, therapist],
  )

  const showJoinVideocall = useMemo(
    () => ['CONFIRMED', 'ONGOING', 'PAID'].includes(status) && url && !matchPatientDetailPath,
    [status, url, matchPatientDetailPath],
  )

  const showBookNext = useMemo(
    () => (['ENDED', 'ONGOING', 'NO_SHOW'] as TherapySessionStatus[]).includes(status) && !futureTherapySession,
    [futureTherapySession, status],
  )

  const showBookNextVariant1 = useMemo(() => !matchChatPath && !matchPatientPath, [matchChatPath, matchPatientPath])

  const showStopTherapySession = useMemo(
    () =>
      (['ENDED', 'ONGOING'] as TherapySessionStatus[]).includes(status) &&
      !churn &&
      (!futureTherapySession || (futureTherapySession && recurrency)),
    [churn, futureTherapySession, recurrency, status],
  )

  const largeWrapperPaddingTop = useMemo(
    (): Spacing => ((showJoinVideocall || showBookNext || showStopTherapySession) && matchChatPath ? 16 : 0),
    [showBookNext, showJoinVideocall, showStopTherapySession, matchChatPath],
  )

  return (
    <Flex lgPt={largeWrapperPaddingTop} pt={16}>
      {showJoinVideocall && url && (
        <JoinVideocallButton patientId={patient.id} provider={provider} status={status} url={url} />
      )}

      {showBookNextVariant1 && showBookNext && (
        <Flex pt={showJoinVideocall ? 16 : 0}>
          <BookNextButton
            kind={status === 'ONGOING' && !isInTherapySessionLive ? 'secondary' : 'primary'}
            therapyId={therapyId}
          />
        </Flex>
      )}

      {showStopTherapySession && (
        <Flex pt={showJoinVideocall || showBookNext ? 16 : 0}>
          <StopTherapySessionButton id={id} patient={patient} />
        </Flex>
      )}
    </Flex>
  )
}
