import loadable from '@loadable/component'
import { Flex, MaxWidth1260px } from 'cdk'
import { BORDER_RADIUS_0, BORDER_WIDTH_0, SPACING_MD, TIME_500 } from 'design-tokens'
import styled from 'styled-components'
import { Card, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useBreakpoints } from '~/hooks/useBreakpoints'
import { TranslationId } from '~/i18n/types'

const LazyClinicalDirectorAvatar = loadable(() => import('./ClinicalDirectorAvatar.lazy'), {
  resolveComponent: (components) => components.ClinicalDirectorAvatar,
})

const ToastCard = styled(Card)`
  @keyframes translate-in {
    from {
      transform: translate(0, 200px);
    }

    to {
      transform: translate(0, 0);
    }
  }

  border: ${BORDER_WIDTH_0};
  border-radius: ${BORDER_RADIUS_0};
  transform: translate(0, 200px);
  animation: 700ms ${TIME_500} translate-in forwards;
`

type ClinicalDirectorQuoteProps = {
  title:
    | Extract<TranslationId, `clinicalTests.clinicalDirector.${string}.title`>
    | Extract<TranslationId, `formFlow.clinicalDirector.quote${string}.title`>
  description:
    | Extract<TranslationId, `formFlow.clinicalDirector.quote${string}.description`>
    | Extract<TranslationId, `clinicalTests.clinicalDirector.${string}.description`>
}

export const ClinicalDirectorQuoteToast = ({ title, description }: ClinicalDirectorQuoteProps) => {
  const { isSmallOnly } = useBreakpoints()

  return (
    <ToastCard backgroundColorName="darker" size="md">
      <MaxWidth1260px $direction="row" $gap={SPACING_MD} $justify="center">
        <Flex>
          <LazyClinicalDirectorAvatar size="md" />
        </Flex>
        <Flex $gap={4} $justify="center" $shrink={1}>
          <Text colorName="lighter" fontWeight="600" kind={isSmallOnly ? 'paragraph' : 'h2'}>
            <Translation id={title} />
          </Text>
          <Text colorName="lighter" fontWeight="400" kind={isSmallOnly ? 'caption' : 'h3'}>
            <Translation id={description} />
          </Text>
        </Flex>
      </MaxWidth1260px>
    </ToastCard>
  )
}
